<template>
    <div class="com-chart2" ref="mpGraph"></div>
</template>
<script>

    export default {
        name: "mpGraph",
        props: [
            'beforeData',
            'afterData',
            'xTime',
            'title'
        ],
        data() {
            return {
                myChart: null

            }
        },
        watch: {
          beforeData: {
            handler(val) {
              console.log(val);
              this.updateChart();

            },
            deep: true,
            immediate: true,
          },
          afterData: {
            handler(val) {
              console.log(val);
              this.updateChart();

            },
            deep: true,
            immediate: true,
          },
          xTime: {
            handler(val) {
              console.log(val);
              this.updateChart();

            },
            deep: true,
            immediate: true,
          }
        },
        mounted() {
            this.initCharts();
            this.updateChart();
            // this.timer =  setInterval(()=> {
            //     this.updateChart()
            //   }, 2000)
        },
        // destroyed() {
        //         clearInterval(this.timer)
        
        //     },
        methods: {
            // 初始化图表   
            initCharts() {
                
                this.myChart = this.$echarts.init(this.$refs.mpGraph);
                const option1 = {
                    title: [{

                        // text: '抽放泵开停（开停状态）',
                        text:this.title,
                        left: '5%',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 'bold'
                        }
                    }, {
                        // text: '平均值：0.2mg/m3',
                        right: '5%',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 'bold'
                        },

                    }],
                    tooltip: {
                        trigger: 'axis',
                        // axisPointer: {
                        //     type: 'cross',
                        //     label: {
                        //         backgroundColor: '#6a7985'
                        //     }
                        // }
                    },
                    legend: {
                        data: ['单位：m/s']
                    },
                    grid: {
                        top: '15%',
                        left: '1%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        boundaryGap: false,
                        // data: ['1:00', '2:00', '3:00', '4:00', '5:00'],
                        data:this.xTime,
                        // 修改x轴文字颜色和大小
                        axisLabel: {
                            textStyle: {
                                color: "rgba(187, 174, 200, 1)",
                                fontSize: "12"
                            }
                        },
                        // 修改分割线的颜色
                        splitLine: {
                            lineStyle: {
                                color: "rgba(199, 186, 212, 0.3)",
                            }
                        }
                    }],
                    yAxis: [{
                            type: 'value',
                            name: 'mg/m3',
                            nameLocation: "middle",
                            // grid: {
                            //     left: '18%',
                            // },

                            axisLine: {
                                // 修改Y轴坐标轴颜色
                                lineStyle: {
                                    color: "rgba(187, 174, 200, 1)",

                                }
                            },
                            axisLabel: {
                                //修改y轴数据颜色
                                textStyle: {
                                    color: "rgba(187, 174, 200, 1)",
                                    fontSize: 12
                                }
                            },
                            // 修改分割线的颜色
                            splitLine: {
                                lineStyle: {
                                    color: "rgba(199, 186, 212, 0.3)",
                                }
                            }
                        }

                    ],
                }
                 this.myChart.setOption(option1);

            },
       
            // 更新图表
            updateChart() {
                const option2 = {
                    series: [{              
                        type: 'line',
                        smooth: true,
                        // 单独修改当前线条的样式
                        lineStyle: {
                            color: "#31d3e1",
                            width: "2"
                        },
                        // 设置拐点
                        symbol: "circle",
                        // 拐点大小
                        symbolSize: 3,
                        // 开始不显示拐点， 鼠标经过显示
                        showSymbol: false,
                        // 设置拐点颜色以及边框
                        itemStyle: {
                            color: "#00e100",
                            borderColor: "rgba(0, 170, 255, 0.1)",
                            borderWidth: 6
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        // data: [0.25, 0.26, 0.13, 0.37, 0.25, 0.32, 0.21, 0.31]
                        data:this.beforeData
                    },
                    {
                        // name: '采煤机平均采煤量',
                        type: 'line',
                        smooth: true,
                        // 单独修改当前线条的样式
                        lineStyle: {
                            color: "#5F9EA0",
                            width: "2"
                        },
                        // 设置拐点
                        symbol: "circle",
                        // 拐点大小
                        symbolSize: 3,
                        // 开始不显示拐点， 鼠标经过显示
                        showSymbol: false,
                        // 设置拐点颜色以及边框
                        itemStyle: {
                            color: "#00e100",
                            borderColor: "rgba(0, 170, 255, 0.1)",
                            borderWidth: 6
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        // data: [0.45, 0.16, 0.33, 0.27, 0.35, 0.12, 0.21, 0.31]
                        data:this.afterData
                    }]
                };
            //    this.xTime.shift();
            //    this.xTime.push()
                // this.xTime.shift();
                this.myChart.setOption(option2);
           
            }

           
            
        }
    }
</script>
<style scoped>
    .com-chart {
        height: 145px;
        width:500px;
    }
</style>