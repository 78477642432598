<template>
	<div id="myChart"></div>
</template>

<script>

	export default {
		name: 'DynamicLineChart',
		 props:{
			beforeData:{
				type:Array,
				default:6
			}
  		},
		data() {
			return {
				// 实时数据数组
				myChart: null,
				date: [],
				beforeData: [],
				yieldIndex: [],
				// 折线图echarts初始化选项
				echartsOption: {
					legend: {
						data: ['实际收益率', '大盘收益率'],
					},
					xAxis: {
						name: '时间',
						nameTextStyle: {
							fontWeight: 600,
							fontSize: 18
						},
						type: 'category',
						boundaryGap: false,
						data: this.date, // 绑定实时数据数组
					},
					yAxis: {
						name: '实际收益率',
						nameTextStyle: {
							fontWeight: 600,
							fontSize: 18
						},
						type: 'value',
						scale: true,
						boundaryGap: ['15%', '15%'],
						axisLabel: {
							interval: 'auto',
							formatter: '{value} %'
						}
					},
					tooltip: {
						trigger: 'axis',
					},
					series: [{
							name: '实际收益率',
							type: 'line',
							smooth: true,
							data: this.beforeData, // 绑定实时数据数组
						},
						{
							name: '大盘收益率',
							type: 'line',
							smooth: true,
							data: this.yieldIndex, // 绑定实时数据数组
						}
					]
				}
			}
		},
		mounted() {
			console.log("子组件里的mounted");
			this.myChart = this.$echarts.init(document.getElementById('myChart'), 'light'); // 初始化echarts, theme为light
			this.myChart.setOption(this.echartsOption); // echarts设置初始化选项
			setInterval(this.addData, 3000); // 每三秒更新实时数据到折线图
		},
		methods: {
			// 生成随机数组
			getRandomArr:function(){
				var rnd=[];
				for(var i=0;i<10;i++)
					rnd[i] = Math.floor(Math.random()*10);
				return rnd;

			},
			// 获取当前时间
			getTime: function() {
				var ts = arguments[0] || 0;
				var t, h, i, s;
				t = ts ? new Date(ts * 1000) : new Date();
				h = t.getHours();
				i = t.getMinutes();
				s = t.getSeconds();
				// 定义时间格式
				return (h < 10 ? '0' + h : h) + ':' + (i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s);
			},
			// 添加实时数据
			addData: function() {
				// 从接口获取数据并添加到数组
				// this.$axios.get('url').then((res) => {
					// 把数字转换为字符串，结果的小数点后有指定位数的数字 	round(x)四舍五入
					// this.yieldRate.push((Math.round(Math.random())* 100).toFixed(3));
					// this.yieldIndex.push((Math.round(Math.random()) * 100).toFixed(3));
					// // x轴时间
					this.date.push(this.getTime(Math.round(new Date().getTime() / 1000)));
					// 重新将数组赋值给echarts选项
					this.echartsOption.xAxis.data = this.date;
					// series中的两个数据
					this.echartsOption.series[0].data = this.beforeData;
					this.echartsOption.series[1].data = this.getRandomArr();
					this.myChart.setOption(this.echartsOption);
				// });
			}
		}
	}
</script>

<style>

	#myChart {
		width: 100%;
		height: 500px;
		margin: 0 auto;
	}
</style>
